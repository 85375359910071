@font-face {
    font-family: 'Scada';
    src: local('Scada'), url("../fonts/Scada-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-variation-settings: normal;
}

body, html, #root {
    height: 100%;
    margin: 0;
}

body{
    margin: 0;
    padding: 0;
}

.main-layout{
    margin-top: 50px;
}